var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-right",
      style: { width: _vm.chartWidth },
      attrs: { id: "chart-wrap" }
    },
    [
      _c("apexchart", {
        ref: "chart",
        attrs: {
          width: "102%",
          height: "200mm",
          type: "bar",
          options: _vm.chartOptions,
          series: _vm.fromSeries
        }
      }),
      _vm._l(_vm.colorList, function(val, key) {
        return _c(
          "div",
          {
            staticClass: "chart-regend ml-2 cart-regend-item ",
            staticStyle: {
              "margin-top": "-30px",
              "font-size": "2.5mm",
              "font-weight": "bold"
            },
            style: { padding: "0", border: "solid  " + val }
          },
          [_vm._v(" " + _vm._s(key) + " ")]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }